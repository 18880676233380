import React from 'react';
import '../hojas-de-estilo/Informacion.css'

function Informacion() {
    return (
        <div className='contenedor-info'>
            <img 
            className='imagen-info'
            src={require(`../imagens/perfil1.png`)}
            alt='Foto de perfil 1'/>
            <div className='contenedor-texto-info'>
                <p className='nombre-testimonio'>ExpApp</p>
                <p className='cargo-testimonio'>Nos reservamos el derecho de admisión</p>
                <p className='texto-testimonio'>Tenemos una filosofía de discreción y exclusividad, por esta razon el acceso es unicamente por nuestra AppMovil. Cada nuevo usuario debe presentar su solicitud por nuestro canal aquí</p>
            </div>
        </div>
    )
}

export default Informacion;