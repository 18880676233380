import './App.css';
import Informacion from './components/Informacion';

function App() {
  return (
    <div className="App">
      <div className='contenedor-principal'>
        <Informacion 
          />
      </div>
    </div>
  );
}

export default App;
